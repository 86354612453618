import React from 'react';
import { withTranslation } from 'react-i18next';
import {bindActionCreators} from 'redux';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import Loader from 'react-loader-spinner';
import {toast} from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import {loadStripe} from '@stripe/stripe-js';
import InjectedCheckoutForm from './checkoutForm';
import {Elements} from '@stripe/react-stripe-js';
import Tooltip from '@material-ui/core/Tooltip';

import i18n from "../../../i18n";

import ProjectService from '../../../services/project.service';

import StripeService from '../../../services/stripe.service';

import './settings.scss';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

class DashboardSettingsBilling extends React.Component {
	constructor(props) {
		super(props);
		this.state = {errors: [], invoices: [], subscriptions: [], cards: [], deleteCardDisabled: false, bigLoading: true, billingDetails: {}, taxes: []}
		this.signout = this.signout.bind(this);
		this.submitVat = this.submitVat.bind(this);
		this.onChangeVat = this.onChangeVat.bind(this);
		this.deleteCard = this.deleteCard.bind(this);
		this.setDefaultCard = this.setDefaultCard.bind(this);
		this.cancelEmailvalidation = this.cancelEmailvalidation.bind(this);
		this.cancelSpamchecker = this.cancelSpamchecker.bind(this);
		this.cancelWarmer = this.cancelWarmer.bind(this);
		this.onChangeCustomer = this.onChangeCustomer.bind(this);
		this.updateCustomer = this.updateCustomer.bind(this);
	}

	componentWillMount() {
		window.gtag("event", "billing", {
            event_category: "access",
            event_label: "billing_page"
        });
    ProjectService.get(this.props.match.params.project_id).then((response) => {
      localStorage.setItem('x-api-key', response.body.api_key);


			if(response.body.config_feature_stripe_payment_link_checkout_enabled && ((queryParams.spamCheckerPlan))){
				this.setState({project: response.body});
				let checkoutData = {};

				checkoutData.selectedSpamcheckerPlan = queryParams.spamCheckerPlan;
				checkoutData.selectedSpamcheckerPeriod = queryParams.monthly == 'true' ? 'totalSubPricing' : 'totalMonthlyPricing';

				// Generate a payment link or buy seats
				StripeService.generatePaymentLink(checkoutData).then((response) => {
					// Redirect when the link is generate
					if(response.body.redirect_url){
						window.location.replace(response.body.redirect_url);
					} else {
						window.gtag("event", "billing", { event_category: "credit_card", event_label: "added"});
						window.$crisp.push(["set", "session:segments", [["cb-added"]]]);
						window.$crisp.push(["set", "session:event", [[["cb-added"]]]])

						if(this.state.checkoutData.seats){

							if(this.state.checkoutData.seats > 0){
								window.gtag("event", "purchase", {
									currency: 'USD',
									value: (this.state.checkoutData.seats*25),
									transaction_id: Date.now(),
									items: [
										{item_iname: 'warmer_seats'}
									]
								});
							}
						} else {
							window.gtag("event", "purchase", {
								currency: 'USD',
								value: 25,
								transaction_id: Date.now(),
								items: [
									{item_iname: 'warmer_seats'}
								]
							});
						}

						if(this.state.queryString.spamCheckerPlan){
							toast.success('Thanks, you can use the Spamchecker');

							window.gtag("event", "spamchecker", {
											event_category: "spamchecker",
											event_label: "buy-credits"
									});
								window.gtag('event', 'conversion', {
										'send_to': 'AW-444511731/aidcCIOXkaUDEPPr-tMB',
										'transaction_id': ''
								});

								// BING ads
								window.uetq = window.uetq || [];
								window.uetq.push('event', 'buy spam test credits', {});

							this.props.history.push(`/dashboard/${this.props.projectId}/spam-tests/home`);
						}else{
							if(this.props.currentProject.config_feature_new_billing_seats){
								toast.success('All good, you can now connect your email account(s) 🙌');
								this.props.history.push(`/dashboard/${this.props.currentProject.id}/account/connect`);
							}else{
								toast.success('Thanks, you can now connect account(s) ! You will be charged for each accounts');
								this.props.history.push(`/dashboard/${this.props.currentProject.id}/home?addModalAccount=true`);
							}
						}
					}
				})
				.catch((error) => {
					window.$crisp.push(["set", "session:segments", [["cb-refused"]]]);
					toast.error('Your card was rejected, try another card. Contact us if the problem persists.');
					Sentry.captureException(err);

					// Of carte if declined, redirect to add a card on Stripe portal
					console.error(error);
					this.setState({error: true, loading: false});
				});
			} else {
				this.setState({project: response.body, bigLoading: false});
			}

			StripeService.cards().then((response) => {
				let hideAddCardForm = false;
				if(response.body.data.length > 0){
					hideAddCardForm = true;
				}
				this.setState({cards: response.body.data, hideAddCardForm: this.props.currentProject.config_feature_new_billing_seats ? hideAddCardForm : false});
			})
			.catch((error) => {
				console.log(error);
				this.setState({error: true,});
				toast.error('Oops, we have an error');
			});

			if(this.props.currentProject.config_feature_stripe_payment_link_checkout_enabled){
				StripeService.portalLink('payment_method_update').then((response) => {
					this.setState({updateCardPortalLink: response.body.url});
				})
				.catch((error) => {
					this.setState({error: true,});
					toast.error('Oops, we have an error');
				});
			}
    })
    .catch((error) => {
      this.setState({error: true,});
      toast.error('Oops, we have an error');
    });

		StripeService.subscriptions().then((response) => {
			this.setState({subscriptions: response.body.data})
		})
		.catch((error) => {
			this.setState({error: true,});
			toast.error('Oops, we have an error');
		});

		if(this.props.currentProject.config_feature_stripe_payment_link_checkout_enabled){
			StripeService.taxes().then((response) => {
				this.setState({taxes: response.body.data})
			})
			.catch((error) => {
				this.setState({error: true,});
				toast.error('Oops, we have an error');
			});
		}

		StripeService.customer().then((response) => {
			this.setState({customer: response.body});
		})
		.catch((error) => {
			this.setState({error: true,});
			toast.error('Oops, we have an error');
		});

		StripeService.invoices().then((response) => {
			this.setState({invoices: response.body.data});
			console.log(response.body.data)
			if(response.body.data.length == 0 && this.props.currentProject.config_feature_stripe_payment_link_checkout_enabled){
				this.props.history.push(`/dashboard/${this.props.currentProject.id}/settings/checkout/plans`);
			}
		})
		.catch((error) => {
			this.setState({error: true,});
			toast.error('Oops, we have an error');
		});

		StripeService.vats().then((response) => {
			this.setState({vat_id: response.body.value});
		})
		.catch((error) => {
			this.setState({error: true,});
			toast.error('Oops, we have an error');
		});

		let queryParams = queryString.parse(this.props.location.search);
		let hideSignout = queryParams.hide_signout;
		this.setState({hideSignout, queryString: queryParams});
	}

	componentWillReceiveProps(nextProps) {
	}

	signout(){
		localStorage.clear();
		//this.props.history.push(`/redirect`);
	}

	submitVat(e){
		e.preventDefault();
		StripeService.setVat(this.state.vat_id).then((response) => {
			toast.success('VAT number saved !');
		})
		.catch((error) => {
			this.setState({error: true,});
			toast.error('Invalid VAT number');
		});
	}

	onChangeVat(e){
    let vat_id = e.target.value;
    return this.setState({vat_id});
	}

	setDefaultCard(id){
		StripeService.setDefaultCard(id).then((response) => {
			toast.success('Default card updated !');
			StripeService.customer().then((response) => {
				this.setState({customer: response.body});
			})
			.catch((error) => {
				this.setState({error: true,});
				toast.error('Oops, we have an error');
			});
		})
		.catch((error) => {
			this.setState({error: true,});
			toast.error('Invalid VAT number');
		});
	}

	cancelEmailvalidation(){
		let c = this;
		StripeService.cancel('emailvalidation').then(function(response) {
			toast.error('Subscription cancelled');
			c.props.history.push(`/dashboard/${c.props.projectId}/home`);
		})
		.catch((error) => {
			c.setState({error: true});
			toast.error('Oops, we have an error');
			console.error(error);
		});
	}

	cancelWarmer(){
		let c = this;
		StripeService.cancel('warmer').then(function(response) {
			toast.error('Subscription cancelled');
			c.props.history.push(`/dashboard/${c.props.projectId}/home`);
		})
		.catch((error) => {
			c.setState({error: true});
			toast.error('Oops, we have an error');
			console.error(error);
		});
	}

	cancelSpamchecker(){
		let c = this;
		StripeService.cancel('spamchecker').then(function(response) {
			toast.error('Subscription cancelled');
			c.setState({showCancelSpamcheckerModal: false});
			c.props.history.push(`/dashboard/${c.props.projectId}/home`);
		})
		.catch((error) => {
			c.setState({error: true});
			toast.error('Oops, we have an error');
			console.error(error);
		});
	}

	deleteCard(id){
		this.setState({deleteCardDisabled: true});
		StripeService.removeCard(id).then((response) => {
			toast.success('Card removed !');
			StripeService.cards().then((response) => {
				this.setState({cards: response.body.data, deleteCardDisabled: false});
			})
			.catch((error) => {
				this.setState({error: true, deleteCardDisabled: false});
				toast.error('Oops, we have an error');
			});
		})
		.catch((error) => {
			this.setState({error: true, deleteCardDisabled: false});
			toast.error('Oops, we have an error');
		});
	}

	onChangeCustomer(event) {
		const field = event.target.name;

		let customer = this.state.customer;

		if(['full_name', 'company', 'card_name', 'line1', 'postal_code', 'city', 'country'].includes(field)){
			customer['address'][field] = event.target.value;
		}else{
			customer[field] = event.target.value;
		}

		this.setState({customer})
		return;
	}

	updateCustomer(){
		StripeService.setCustomer(this.state.customer).then((response) => {
			this.setState({customer: response.body, showEditBillingDetailsForm: false});
			toast.success('Informations updated');
		})
		.catch((error) => {
			this.setState({error: true});
			toast.error('Oops, we have an error');
			console.error(error);
		});
	}


	render() {

		if(this.state.bigLoading){
			return(
				<div className="app container text-center">
					<div className="loader">
						<Loader
						 type="Oval"
						 color="#b7b7b7"
						 height="100"
						 width="100"
						/>
					</div>
				</div>
			)
		}

		return (
			<div className={"app container add-credit-card" + (this.props.currentProject.config_feature_stripe_payment_link_checkout_enabled ? ' billing-home-v2' : '')}>

				{!this.props.currentProject.config_feature_stripe_payment_link_checkout_enabled &&
					<Elements stripe={stripePromise}>
						<InjectedCheckoutForm queryString={this.state.queryString} hideAddCardFrom={this.state.hideAddCardForm} projectId={this.props.match.params.project_id} project={this.props.currentProject} history={this.props.history} />
					</Elements>
				}

        <div className="col-md-8">
				<div className="row">
					{this.props.currentProject.config_feature_stripe_payment_link_checkout_enabled &&
						<div className="card col-md-12">
							<h1 className="pull-left no-padding col-md-12">Billing details</h1><br />
							<p>Manage your billing information</p>
						</div>
					}


					{((this.state.cards.length > 0) || (this.state.subscriptions.length > 0) || this.props.currentProject.stripe_customer_id) &&
						<>
						<h2 className="pull-left no-padding">Cards</h2>
						{!this.props.currentProject.config_feature_new_billing_seats &&
							<>
								{this.state.hideAddCardForm &&
									<div className="btn purple-btn pull-right add-cb" onClick={() => this.setState({hideAddCardForm: false})}>
										Add card
									</div>
								}
							</>
						}

						{this.props.currentProject.config_feature_new_billing_seats &&
							<>
								{this.state.updateCardPortalLink &&
									<a className="btn purple-btn pull-right add-cb" href={this.state.updateCardPortalLink}>
										Add card
									</a>
								}

								<>
									{(this.state.hideAddCardForm && !this.state.updateCardPortalLink) &&
										<div className="btn purple-btn pull-right add-cb" onClick={() => this.setState({hideAddCardForm: false})}>
											Add card
										</div>
									}
								</>
							</>
						}

						<div className="clearfix"></div>

							<div className="card">

								<ul className="invoices">
									{this.state.cards.map((card, index) => {
										return (
											<li className="no-padding-left">
												<i className={"fa-brands fa-cc-" + card.card.brand}></i> <span className="card-nb">**** **** **** {card.card.last4}</span> {card.card.exp_month}/{card.card.exp_year}
												{this.state.customer &&
													<>
														{card.id == this.state.customer.invoice_settings.default_payment_method &&
															<>
																<span className="default-payment">
																	Default
																</span>

																{(!this.props.currentProject.stripe_subscription_id && !this.props.currentProject.free_billing) &&
																	<button type="submit" class="btn btn-primary red pull-right" onClick={() => this.deleteCard(card.id)} disabled={this.state.deleteCardDisabled}>
																		Remove
																	</button>
																}
															</>
														}

														{card.id != this.state.customer.invoice_settings.default_payment_method &&
															<>
																<button type="submit" class="btn btn-secondary red-txt pull-right" onClick={() => this.deleteCard(card.id)} disabled={this.state.deleteCardDisabled}>
																  Remove
																</button>
																<button type="submit" class="btn btn-secondary pull-right" onClick={() => this.setDefaultCard(card.id)} disabled={this.state.deleteCardDisabled}>Set as default</button>
															</>
														}
													</>
												}
											</li>
									)})}
								</ul>
								<div className="clearfix"></div>
							</div>
						</>
					}

					{this.state.subscriptions.length > 0 &&
						<>
														<h2>Active subscription(s)</h2>
							<div className="card">
								<ul className="invoices">
									{this.state.subscriptions.map((subscription, index) => {
										return (
											<>
											{(!subscription.emailvalidation && !subscription.spamchecker) &&
												<>
													{subscription.items.data.map((item, index) => {
														if(item.quantity > 0){
															if(!item.spamchecker){
																return (
																	<li className="no-padding-left">
																		Warmer - {item.quantity} seat(s) at {item.plan.amount/100} {item.plan.currency} per {item.price.recurring.interval}


																		{this.props.currentProject.config_feature_stripe_payment_link_checkout_enabled &&
																			<>
																				<button className='btn btn-primary red pull-right btn-cancel-subscription' onClick={() => this.setState({showCancelWarmerModal: true})}>Cancel</button>
																				<NavLink to={`/dashboard/${this.props.match.params.project_id}/settings/checkout/plans`} className="btn btn-secondary pull-right">
																					Edit
																				</NavLink>
																			</>
																		}

																		{(this.props.currentProject.config_feature_new_billing_seats && !this.props.currentProject.config_feature_stripe_payment_link_checkout_enabled) &&
																			<div className="help-bubble pull-right">
																				<Tooltip title="To edit or cancel your subscription, please add or remove seats above" placement="bottom">
																					<div>
																						<i class="fas fa-info-circle"></i>
																					</div>
																				</Tooltip>
																			</div>
																		}

																		{!this.props.currentProject.config_feature_new_billing_seats &&
																			<div className="help-bubble pull-right">
																				<Tooltip title="To edit or cancel your subscription, you can add or disconnect your email accounts. To do it : Go on the Email Warmer menu > Find your email account and click on 'Show' > Settings > Disconnect and stop warming > Disconnect (the red button)" placement="bottom">
																					<div>
																						<i class="fas fa-info-circle"></i>
																					</div>
																				</Tooltip>
																			</div>
																		}
																	</li>
																)
															} else {
																return (
																	<li className="no-padding-left">
																		Spam Tester - {item.quantity} verifications per month


																		<button className='btn btn-primary red pull-right btn-cancel-subscription' onClick={() => this.setState({showCancelSpamcheckerModal: true})}>Cancel</button>

																		{this.props.currentProject.config_feature_stripe_payment_link_checkout_enabled &&
																			<NavLink to={`/dashboard/${this.props.match.params.project_id}/settings/checkout/plans`} className="btn btn-secondary pull-right">
																				Edit
																			</NavLink>
																		}
																		{!this.props.currentProject.config_feature_stripe_payment_link_checkout_enabled &&
																			<NavLink to={`/dashboard/${this.props.match.params.project_id}/settings/spamchecker`} className="btn btn-secondary pull-right">
																				Edit
																			</NavLink>
																		}
																	</li>
																)
															}

														}}
														)}
												</>
											}

											{(subscription.emailvalidation && !subscription.spamchecker) &&
												<>
													{subscription.items.data.map((item, index) => {
														if(item.quantity > 0){
															return (
																<li className="no-padding-left">
																	Verifier - {item.quantity} verifications per month


																	 <button className='btn btn-primary red pull-right btn-cancel-subscription' onClick={() => this.setState({showCancelEmailvalidationModal: true})}>Cancel</button>
																	<NavLink to={`/dashboard/${this.props.match.params.project_id}/settings/emailvalidation`} className="btn btn-secondary pull-right">
																		Edit
																	</NavLink>
																</li>
															)}}
														)}
												</>
											}

											{subscription.spamchecker &&
												<>
													{subscription.items.data.map((item, index) => {
														if(item.quantity > 0){
															return (
																<li className="no-padding-left">
																	Spam Tester - {item.quantity} verifications per month


																	 <button className='btn btn-primary red pull-right btn-cancel-subscription' onClick={() => this.setState({showCancelSpamcheckerModal: true})}>Cancel</button>
																	<NavLink to={`/dashboard/${this.props.match.params.project_id}/settings/spamchecker`} className="btn btn-secondary pull-right">
																		Edit
																	</NavLink>
																</li>
															)}}
														)}
												</>
											}
											</>
									)})}
								</ul>
								<div className="clearfix"></div>
							</div>
						</>
					}



					{(this.state.customer && this.props.currentProject.config_feature_stripe_payment_link_checkout_enabled) &&
						<>
							<h2>Customer details</h2>


							{!this.state.showEditBillingDetailsForm &&
								<div className="btn purple-btn pull-right add-cb" onClick={() => this.setState({showEditBillingDetailsForm: true})}>
									Edit
								</div>
							}

							{this.state.showEditBillingDetailsForm &&
								<div className="btn purple-btn pull-right add-cb" onClick={() => this.updateCustomer()}>
									Save
								</div>
							}

							{this.state.showEditBillingDetailsForm &&
								<div className="card col-md-12 no-padding-left customer-details imap">
									<form>
										<div className="col-md-12 no-padding-left">
											<div className="fieldCont col-md-6 no-padding-left">
												<label>Full name</label>
												<input type="text" name="full_name" onChange={this.onChangeCustomer} value={this.state.billingDetails.full_name} required/>
											</div>
											<div className="fieldCont col-md-6">
												<label>Company</label>
												<input type="text" name="company" onChange={this.onChangeCustomer} value={this.state.billingDetails.company} required/>
											</div>
											<div class="fieldCont col-md-12 no-padding-left">
						            <label>Address</label>
						            <input type="text" name="line1" onChange={this.onChangeCustomer} value={this.state.customer.address.line1} required/>
						          </div>

						          <div className="fieldCont col-md-4 no-padding-left">
						            <label>Postal code</label>
						            <input type="text" name="postal_code" onChange={this.onChangeCustomer} value={this.state.customer.address.postal_code} required/>
						          </div>

											<div className="fieldCont col-md-4">
												<label>City</label>
												<input type="text" name="city" onChange={this.onChangeCustomer} value={this.state.customer.address.city} required/>
											</div>


											<div className="fieldCont col-md-4">
												<label>Country</label>

											<select name="country" onChange={this.onChangeCustomer} value={this.state.customer.address.country} required>
												<option value="">Country</option>
												<option value="AF">Afghanistan</option>
												<option value="AX">Åland Islands</option>
												<option value="AL">Albania</option>
												<option value="DZ">Algeria</option>
												<option value="AS">American Samoa</option>
												<option value="AD">Andorra</option>
												<option value="AO">Angola</option>
												<option value="AI">Anguilla</option>
												<option value="AQ">Antarctica</option>
												<option value="AG">Antigua and Barbuda</option>
												<option value="AR">Argentina</option>
												<option value="AM">Armenia</option>
												<option value="AW">Aruba</option>
												<option value="AU">Australia</option>
												<option value="AT">Austria</option>
												<option value="AZ">Azerbaijan</option>
												<option value="BS">Bahamas</option>
												<option value="BH">Bahrain</option>
												<option value="BD">Bangladesh</option>
												<option value="BB">Barbados</option>
												<option value="BY">Belarus</option>
												<option value="BE">Belgium</option>
												<option value="BZ">Belize</option>
												<option value="BJ">Benin</option>
												<option value="BM">Bermuda</option>
												<option value="BT">Bhutan</option>
												<option value="BO">Bolivia, Plurinational State of</option>
												<option value="BQ">Bonaire, Sint Eustatius and Saba</option>
												<option value="BA">Bosnia and Herzegovina</option>
												<option value="BW">Botswana</option>
												<option value="BV">Bouvet Island</option>
												<option value="BR">Brazil</option>
												<option value="IO">British Indian Ocean Territory</option>
												<option value="BN">Brunei Darussalam</option>
												<option value="BG">Bulgaria</option>
												<option value="BF">Burkina Faso</option>
												<option value="BI">Burundi</option>
												<option value="KH">Cambodia</option>
												<option value="CM">Cameroon</option>
												<option value="CA">Canada</option>
												<option value="CV">Cape Verde</option>
												<option value="KY">Cayman Islands</option>
												<option value="CF">Central African Republic</option>
												<option value="TD">Chad</option>
												<option value="CL">Chile</option>
												<option value="CN">China</option>
												<option value="CX">Christmas Island</option>
												<option value="CC">Cocos (Keeling) Islands</option>
												<option value="CO">Colombia</option>
												<option value="KM">Comoros</option>
												<option value="CG">Congo</option>
												<option value="CD">Congo, the Democratic Republic of the</option>
												<option value="CK">Cook Islands</option>
												<option value="CR">Costa Rica</option>
												<option value="CI">Côte d'Ivoire</option>
												<option value="HR">Croatia</option>
												<option value="CU">Cuba</option>
												<option value="CW">Curaçao</option>
												<option value="CY">Cyprus</option>
												<option value="CZ">Czech Republic</option>
												<option value="DK">Denmark</option>
												<option value="DJ">Djibouti</option>
												<option value="DM">Dominica</option>
												<option value="DO">Dominican Republic</option>
												<option value="EC">Ecuador</option>
												<option value="EG">Egypt</option>
												<option value="SV">El Salvador</option>
												<option value="GQ">Equatorial Guinea</option>
												<option value="ER">Eritrea</option>
												<option value="EE">Estonia</option>
												<option value="ET">Ethiopia</option>
												<option value="FK">Falkland Islands (Malvinas)</option>
												<option value="FO">Faroe Islands</option>
												<option value="FJ">Fiji</option>
												<option value="FI">Finland</option>
												<option value="FR">France</option>
												<option value="GF">French Guiana</option>
												<option value="PF">French Polynesia</option>
												<option value="TF">French Southern Territories</option>
												<option value="GA">Gabon</option>
												<option value="GM">Gambia</option>
												<option value="GE">Georgia</option>
												<option value="DE">Germany</option>
												<option value="GH">Ghana</option>
												<option value="GI">Gibraltar</option>
												<option value="GR">Greece</option>
												<option value="GL">Greenland</option>
												<option value="GD">Grenada</option>
												<option value="GP">Guadeloupe</option>
												<option value="GU">Guam</option>
												<option value="GT">Guatemala</option>
												<option value="GG">Guernsey</option>
												<option value="GN">Guinea</option>
												<option value="GW">Guinea-Bissau</option>
												<option value="GY">Guyana</option>
												<option value="HT">Haiti</option>
												<option value="HM">Heard Island and McDonald Islands</option>
												<option value="VA">Holy See (Vatican City State)</option>
												<option value="HN">Honduras</option>
												<option value="HK">Hong Kong</option>
												<option value="HU">Hungary</option>
												<option value="IS">Iceland</option>
												<option value="IN">India</option>
												<option value="ID">Indonesia</option>
												<option value="IR">Iran, Islamic Republic of</option>
												<option value="IQ">Iraq</option>
												<option value="IE">Ireland</option>
												<option value="IM">Isle of Man</option>
												<option value="IL">Israel</option>
												<option value="IT">Italy</option>
												<option value="JM">Jamaica</option>
												<option value="JP">Japan</option>
												<option value="JE">Jersey</option>
												<option value="JO">Jordan</option>
												<option value="KZ">Kazakhstan</option>
												<option value="KE">Kenya</option>
												<option value="KI">Kiribati</option>
												<option value="KP">Korea, Democratic People's Republic of</option>
												<option value="KR">Korea, Republic of</option>
												<option value="KW">Kuwait</option>
												<option value="KG">Kyrgyzstan</option>
												<option value="LA">Lao People's Democratic Republic</option>
												<option value="LV">Latvia</option>
												<option value="LB">Lebanon</option>
												<option value="LS">Lesotho</option>
												<option value="LR">Liberia</option>
												<option value="LY">Libya</option>
												<option value="LI">Liechtenstein</option>
												<option value="LT">Lithuania</option>
												<option value="LU">Luxembourg</option>
												<option value="MO">Macao</option>
												<option value="MK">Macedonia, the former Yugoslav Republic of</option>
												<option value="MG">Madagascar</option>
												<option value="MW">Malawi</option>
												<option value="MY">Malaysia</option>
												<option value="MV">Maldives</option>
												<option value="ML">Mali</option>
												<option value="MT">Malta</option>
												<option value="MH">Marshall Islands</option>
												<option value="MQ">Martinique</option>
												<option value="MR">Mauritania</option>
												<option value="MU">Mauritius</option>
												<option value="YT">Mayotte</option>
												<option value="MX">Mexico</option>
												<option value="FM">Micronesia, Federated States of</option>
												<option value="MD">Moldova, Republic of</option>
												<option value="MC">Monaco</option>
												<option value="MN">Mongolia</option>
												<option value="ME">Montenegro</option>
												<option value="MS">Montserrat</option>
												<option value="MA">Morocco</option>
												<option value="MZ">Mozambique</option>
												<option value="MM">Myanmar</option>
												<option value="NA">Namibia</option>
												<option value="NR">Nauru</option>
												<option value="NP">Nepal</option>
												<option value="NL">Netherlands</option>
												<option value="NC">New Caledonia</option>
												<option value="NZ">New Zealand</option>
												<option value="NI">Nicaragua</option>
												<option value="NE">Niger</option>
												<option value="NG">Nigeria</option>
												<option value="NU">Niue</option>
												<option value="NF">Norfolk Island</option>
												<option value="MP">Northern Mariana Islands</option>
												<option value="NO">Norway</option>
												<option value="OM">Oman</option>
												<option value="PK">Pakistan</option>
												<option value="PW">Palau</option>
												<option value="PS">Palestinian Territory, Occupied</option>
												<option value="PA">Panama</option>
												<option value="PG">Papua New Guinea</option>
												<option value="PY">Paraguay</option>
												<option value="PE">Peru</option>
												<option value="PH">Philippines</option>
												<option value="PN">Pitcairn</option>
												<option value="PL">Poland</option>
												<option value="PT">Portugal</option>
												<option value="PR">Puerto Rico</option>
												<option value="QA">Qatar</option>
												<option value="RE">Réunion</option>
												<option value="RO">Romania</option>
												<option value="RU">Russian Federation</option>
												<option value="RW">Rwanda</option>
												<option value="BL">Saint Barthélemy</option>
												<option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
												<option value="KN">Saint Kitts and Nevis</option>
												<option value="LC">Saint Lucia</option>
												<option value="MF">Saint Martin (French part)</option>
												<option value="PM">Saint Pierre and Miquelon</option>
												<option value="VC">Saint Vincent and the Grenadines</option>
												<option value="WS">Samoa</option>
												<option value="SM">San Marino</option>
												<option value="ST">Sao Tome and Principe</option>
												<option value="SA">Saudi Arabia</option>
												<option value="SN">Senegal</option>
												<option value="RS">Serbia</option>
												<option value="SC">Seychelles</option>
												<option value="SL">Sierra Leone</option>
												<option value="SG">Singapore</option>
												<option value="SX">Sint Maarten (Dutch part)</option>
												<option value="SK">Slovakia</option>
												<option value="SI">Slovenia</option>
												<option value="SB">Solomon Islands</option>
												<option value="SO">Somalia</option>
												<option value="ZA">South Africa</option>
												<option value="GS">South Georgia and the South Sandwich Islands</option>
												<option value="SS">South Sudan</option>
												<option value="ES">Spain</option>
												<option value="LK">Sri Lanka</option>
												<option value="SD">Sudan</option>
												<option value="SR">Suriname</option>
												<option value="SJ">Svalbard and Jan Mayen</option>
												<option value="SZ">Swaziland</option>
												<option value="SE">Sweden</option>
												<option value="CH">Switzerland</option>
												<option value="SY">Syrian Arab Republic</option>
												<option value="TW">Taiwan, Province of China</option>
												<option value="TJ">Tajikistan</option>
												<option value="TZ">Tanzania, United Republic of</option>
												<option value="TH">Thailand</option>
												<option value="TL">Timor-Leste</option>
												<option value="TG">Togo</option>
												<option value="TK">Tokelau</option>
												<option value="TO">Tonga</option>
												<option value="TT">Trinidad and Tobago</option>
												<option value="TN">Tunisia</option>
												<option value="TR">Turkey</option>
												<option value="TM">Turkmenistan</option>
												<option value="TC">Turks and Caicos Islands</option>
												<option value="TV">Tuvalu</option>
												<option value="UG">Uganda</option>
												<option value="UA">Ukraine</option>
												<option value="AE">United Arab Emirates</option>
												<option value="GB">United Kingdom</option>
												<option value="US">United States</option>
												<option value="UM">United States Minor Outlying Islands</option>
												<option value="UY">Uruguay</option>
												<option value="UZ">Uzbekistan</option>
												<option value="VU">Vanuatu</option>
												<option value="VE">Venezuela, Bolivarian Republic of</option>
												<option value="VN">Viet Nam</option>
												<option value="VG">Virgin Islands, British</option>
												<option value="VI">Virgin Islands, U.S.</option>
												<option value="WF">Wallis and Futuna</option>
												<option value="EH">Western Sahara</option>
												<option value="YE">Yemen</option>
												<option value="ZM">Zambia</option>
												<option value="ZW">Zimbabwe</option>
												</select>
												</div>
										</div>
										<div className="col-md-6">
										</div>
									</form>
								</div>
							}

							{!this.state.showEditBillingDetailsForm &&
							<div className="card col-md-12 no-padding-left customer-details">
								<div className="col-md-6 no-padding-left">
										{this.state.customer.address.city &&
											<div className="line col-md-12 no-padding-left">
												<div className="labela">Address</div>
												<div className="value">{this.state.customer.address.line1}</div>
											</div>
										}


										{this.state.customer.address.city &&
											<div className="line col-md-12 no-padding-left">
												<div className="labela">City</div>
												<div className="value">{this.state.customer.address.city}</div>
											</div>
										}

										{this.state.customer.address.postal_code &&
											<div className="line col-md-12 no-padding-left">
												<div className="labela">Postal code</div>
												<div className="value">{this.state.customer.address.postal_code}</div>
											</div>
										}
								</div>
								<div className="col-md-6">
									<div className="line col-md-12">
										<div className="labela">Country</div>
										<div className="value">{this.state.customer.address.country}</div>
									</div>

									{this.state.customer.address.state &&
										<div className="line col-md-12">
											<div className="labela">State</div>
											<div className="value">{this.state.customer.address.state}</div>
										</div>
									}

									<div className="line col-md-12">
										<div className="labela">Billing email</div>
										<div className="value">{this.state.customer.email}</div>
									</div>

									{this.state.taxes.map((tax, index) => {
										return (
											<div className="line col-md-12">
												<div className="labela">Tax ID</div>
												<div className="value">{tax.value}</div>
											</div>
										)
									})}


								</div>
							</div>
						}

							{this.state.customer.balance < 0 &&
								<>
									<h2 className="h-credit-balance">Credit Balance</h2>
									<div className="pull-right credit-balance">{(this.state.customer.balance)/100}$</div>
									<div className="card col-md-12 no-padding-left">
									</div>
								</>
							}
						</>
					}




						{this.state.invoices.length > 0 &&
							<>
			          <h2>
									Invoices
									<a className="how-billing-works-link" href="https://help.mailreach.co/en/article/how-mailreach-billing-works-understand-your-invoices-1wbh4sq/" target="_blank">How billing works</a>
								</h2>
								<div className="card last">
									<ul className="invoices invoices-list">
											<li className="no-padding-left">
												<div className="col-md-3 no-padding-left"><b>Date</b></div>
												<div className="col-md-3"><b>Invoice number</b></div>
												<div className="col-md-2"><b>Status</b></div>
												<div className="col-md-2"><b>Total</b></div>
												<div className="col-md-2"></div>
											</li>
										{this.state.invoices.map((invoice, index) => {
											if(invoice.status == 'draft'){
												return (<></>);
											}
											return (
											<li className="no-padding-left">
												<div className="col-md-3 no-padding-left">{new Date((invoice.created * 1000)).toLocaleDateString('en-US', { weekday: 'long', day: '2-digit', month: 'long', year: 'numeric' })}</div>
												<div className="col-md-3"><a className="normal-weight" href={invoice.invoice_pdf} target="_blank">{invoice.number}</a></div>
												<div className="col-md-2">{invoice.status}</div>
												<div className="col-md-2">{invoice.amount_due/100} {invoice.currency}</div>
												<div className="col-md-2"><a className="pull-right" href={invoice.invoice_pdf} target="_blank"><i class="fas fa-arrow-down"></i></a></div>
											</li>
										)})}
									</ul>
									<div className="clearfix"></div>
								</div>
							</>
						}
					</div>

        </div>


				<Modal className="reputation-modal" show={this.state.showCancelEmailvalidationModal} onHide={() => this.setState({showCancelEmailvalidationModal: false})}>
					<Modal.Body>
							<>
								<h1 className="text-center">Are you sure you want to cancel?</h1>
								<div className="txt text-center">
									<p>By cancelling your email verifier subscription, you won't be able to verify emails with MailReach anymore. Do you confirm?</p>
								</div>
								<div className="footer text-center del">
									<Button variant="secondary" onClick={() => this.setState({showCancelEmailvalidationModal: false})}>
										Go back
									</Button>
									<Button className="btn purple red" onClick={this.cancelEmailvalidation}>
										Yes, cancel
									</Button>
								</div>
							</>
					</Modal.Body>
				</Modal>

				<Modal className="reputation-modal" show={this.state.showCancelSpamcheckerModal} onHide={() => this.setState({showCancelSpamcheckerModal: false})}>
					<Modal.Body>
							<>
								<h1 className="text-center">Are you sure you want to cancel?</h1>
								<div className="txt text-center">
									<p>By cancelling your Spam Tester subscription, you won't be able to run spam tests anymore. Do you confirm?</p>
								</div>
								<div className="footer text-center del">
									<Button variant="secondary" onClick={() => this.setState({showCancelSpamcheckerModal: false})}>
										Go back
									</Button>
									<Button className="btn purple red" onClick={this.cancelSpamchecker}>
										Yes, cancel
									</Button>
								</div>
							</>
					</Modal.Body>
				</Modal>


				<Modal className="reputation-modal" show={this.state.showCancelWarmerModal} onHide={() => this.setState({showCancelWarmerModal: false})}>
					<Modal.Body>
							<>
								<h1 className="text-center">Are you sure you want to cancel?</h1>
								<div className="txt text-center">
									<p>When stopping email warming, it causes your engagement rate to drop, decrease your sender reputation, deliverability, open rates and simply your sales.</p>
								</div>
								<div className="footer text-center del">
									<Button variant="secondary" onClick={() => this.setState({showCancelWarmerModal: false})}>
										Go back
									</Button>
									<Button className="btn purple red" onClick={this.cancelWarmer}>
										I'm sure to stop. Cancel
									</Button>
								</div>
							</>
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		errors: state.errors,
		currentUser: state.currentUser,
		currentProject: state.currentProject,
		currentPricing: state.currentPricing
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardSettingsBilling));
